@use "../utils/variables" as *;


.funnel-state {
    min-height: 80px;
    position: relative;
    border-bottom: $border;
    background: $white;
    padding: 10px;
    padding-top: 0;

    .reset {
        border: none;
    }

    .funnel-node {
        display: inline-block;

        &:not(:first-child)::before {
            margin: 0 5px;
            content: '⇢';
            position: relative;
            color: darken($border-color, 10%);
            background: none;
            top: 2px;
            left: 2px;
        }
    }

    .help-text {
        margin: 4px;
        font-style: italic;
        font-size: 13px;
        display: block;
        user-select: none;
        color: $mid-grey;

        i {
            position: relative;
            top: 0.5px;
            margin-right: 5px;
            color: darken($border-color, 10%);
        }

        .cell-value {
            font-style: normal;
            text-decoration: underline;
            color: $dark-grey;
            font-weight: 600;
            letter-spacing: 0.2px;

            &:hover {
                color: $blue;
                cursor: pointer;
            }
        }
    }
}
