.view-items-with-images {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    & > header {
        position: relative;
        flex: 0 0 auto;
        z-index: $z-main-header;
        margin-left: 4px;
        margin-right: 4px;
    }
    & > main {
        position: relative;
        flex: 1 1 auto;

    }
}

.view-items-container {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;

    & > main {
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.route-items #view {
    overflow: hidden !important;
}

.loadable-item-view:before {
    background: none !important;
}

.view-items-with-images-container {
    $natural-language-query-height: 40px;
    $toggle-button-width: 100px;

    top: 0px;
    bottom: 0px;
    right: 4px;
    left: 4px;
    overflow: hidden;

    &:not(.initializing) {
        opacity: 1;
        transition: transform 0.6s ease, opacity 0.3s ease;
        .item-actions-panel {
            transition: opacity 0.6s ease;
            .group-by-select ul,
            .right {
                transition: transform 0.7s ease;
            }
        }
        .button-toggle-actions-panel {
            transition: transform 0.6s ease, opacity 0.6s ease;
        }
        .button-export {
            transition: transform 0.6s ease;
            transition-delay: 0.2s;
        }
    }

    &.initializing {
        visibility: hidden;
        opacity: 0;
    }

    .item-actions-panel {
        opacity: 1;
        min-height: 110px;
    }

    &.hide {
        .item-actions-panel {
            opacity: 1;
        }
        .grid-bar {
            .button-export {
                transform: translateX(-$toggle-button-width);
                transition-duration: 0.5s;
            }
        }
        .button-toggle-actions-panel {
            &.button-toggle-actions-panel-hide {
                opacity: 0;
            }
            &.button-toggle-actions-panel-show {
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0.4s;
                transition-duration: 0.3s;
            }
        }
    }

    & > header {
        border: $border;
        border-top: none;
        box-shadow: 0px 1px 5px rgba(black, 0.05);
        background: $white;
        transition: height 0.5s ease-in-out;

        &.hidden {
            overflow: hidden;
        }
    }

    & > main {
        border-left: $border;
        border-right: $border;
        flex-grow: 1;
    }

    &:not(.hide) .button-toggle-actions-panel-hide {
        &:hover {
            span,
            i {
                color: $blue;
            }
        }
    }
    &.hide .button-toggle-actions-panel-show {
        &:hover {
            span,
            i {
                color: $blue;
            }
        }
    }

    .button-toggle-images {
        box-shadow: none;
        border: none;
        display: inline-block;
        vertical-align: middle;
        width: $toggle-button-width;
        opacity: 1;
        box-shadow: none;

        &:active,
        &:hover {
            background: transparent;
            box-shadow: none;
            span,
            i {
                color: $blue !important;
            }
        }

        span {
            margin-left: 5px;
        }

        span,
        i {
            font-size: 11px;
            font-weight: 400;
            color: $light-grey;
        }

        i {
            width: 10px;
            opacity: 0.5;
            position: relative;
            top: -1px;
        }
    }

    .button-toggle-actions-panel {
        box-shadow: none;
        border: none;
        display: inline-block;
        vertical-align: middle;
        width: $toggle-button-width;
        opacity: 1;

        &.button-toggle-actions-panel-hide {
            position: absolute;
            right: 0;
            top: -2px;
            height: 25px;
            line-height: 25px;
            background: none;
            text-align: right;
            margin-right: 14px;
            width: auto;
            z-index: $z-elevated;
            box-shadow: none;

            transform: translateY(-100%);
            span,
            i {
                font-size: 11px;
                font-weight: 400;
                color: $light-grey;
            }
        }
        &.button-toggle-actions-panel-show {
            position: absolute;
            right: 0;
            border-left: $border;
            border-bottom: $border;
            background: $white;
            height: 30px;
            line-height: 30px;
            transform: translateY(-100%);
            span,
            i {
                font-size: 11px;
                font-weight: 400;
                color: $mid-grey;
            }
        }
        i {
            width: 10px;
            opacity: 0.5;
        }
    }

    .grid-bar {
        display: flex;
        flex-direction: row;
        position: relative;
        overflow: hidden;

        .button-export {
            padding-right: 15px;
            user-select: none;
        }

        .item-natural-language-query {
            flex-grow: 1;
            font-size: 14px;
            font-style: italic;
            line-height: $natural-language-query-height;
            padding-left: 10px;
            color: $mid-grey;

            .selected {
                color: $dark-grey;
                text-transform: lowercase;
            }
        }

        .button-export {
            right: 0;
            top: 0;
            height: $natural-language-query-height;
            line-height: $natural-language-query-height;
        }
    }

    .item-grid {
        position: absolute;
        top: $natural-language-query-height;
        left: 0;
        bottom: 0;
        right: 0;
        border-top: $border;
        box-shadow: 0px -1px 3px rgba(black, 0.05);

        .grid-container,
        .grid {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        .grid {
            .ag-header-viewport {
                margin-left: -1px !important;
                .ag-header-cell-text {
                    font-size: 12px;
                    &:before {
                        font-size: 10px;
                        content: '#';
                        color: rgba($light-grey, 0.75);
                        padding-right: 1px;
                    }
                }
            }

            .ag-cell-value {
                user-select: text;
            }

            .ag-center-cols-viewport {
                left: -1px;

                .ag-center-cols-container .ag-row {
                    .ag-cell {
                        width: 200px;
                    }
                }

                .ag-cell.ag-cell-value {
                    padding: 0;
                }

                .item {
                    padding: 7px;
                }
            }

            .ag-cell {
                font-weight: normal;
            }

            .ag-pinned-left-cols-viewport .ag-cell {
                pointer-events: none;
            }

            .item,
            .item-total {
                height: 100%;
                width: 100%;
                padding: 5px;
                text-align: center;
                display: flex;
                flex-direction: column;
                flex-wrap: no-wrap;
                justify-content: flex-end;

                .item-image {
                    text-align: center;
                    margin-bottom: 5px;
                    flex: 0 1 200px;
                    align-self: stretch;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    transform: translateZ(0);
                    user-select: all;
                }

                .group-by-value {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    line-height: 1;
                    word-wrap: break-word;
                    white-space: normal;
                    max-width: 100%;
                    min-width: 100px;

                    span {
                        max-width: 100%;
                        word-wrap: break-word;
                        white-space: normal;
                        min-width: 100px;
                        color: $dark-grey;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 1.3;
                    }
                }

                .item-info {
                    flex: 0 0 auto;
                    align-self: stretch;
                    line-height: 1.3;
                    color: $mid-grey;
                    max-width: 100%;

                    .item-property,
                    .item-extra-properties {
                        font-style: italic;
                    }

                    .item-property {
                        font-size: 12px;
                        display: block;
                        max-width: 100%;
                        word-wrap: break-word;
                        white-space: normal;
                        min-width: 100px;
                    }
                }

                .item-group-by-value {
                    color: $dark-grey;
                    display: block;
                    max-width: 100%;
                    word-wrap: break-word;
                    white-space: normal;
                    min-width: 100px;
                }

                .item-metrics {
                    margin-top: 10px;
                    text-align: left;
                    display: flex;
                    flex-direction: column;

                    .item-metric-group {
                        flex: 0 0 auto;
                        border-top: $border;
                        padding-left: 3px;
                        padding-right: 3px;
                        font-size: 10px;
                        display: flex;
                        flex-direction: column;
                        max-width: 100%;

                        &:first-child {
                            border-top: none;
                        }

                        &:not(.bare) {
                            .item-metric-header-group {
                                display: block;
                                border-color: $border-color;
                            }
                            .item-metric {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-around;
                                padding-left: 4px;
                                .item-metric-header-name {
                                    flex: 1 1 auto;
                                }
                            }
                        }
                        &.bare {
                            flex-direction: row;
                            flex-wrap: wrap;

                            .item-metric-header-group {
                                flex: 1 1 auto;
                            }
                            .item-metric {
                                flex: 0 0 auto;
                                max-width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                        .item-metric-header-group {
                            color: $mid-grey;
                        }
                        .item-metric-header-name {
                            color: $mid-grey;
                            max-width: 100%;
                            white-space: pre-wrap;
                            line-height: 1.1;
                            padding-bottom: 1px;
                            &:first-child {
                                padding-top: 1px;
                            }
                        }
                        .item-metric-value {
                            color: $dark-grey;
                            &.blank {
                                color: $mid-grey;
                                font-style: italic;
                            }
                            .perchevron:before {
                                top: 0;
                                margin-right: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
