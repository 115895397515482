
.item-actions-panel {
    width: 100%;
    max-width: 100%;
    border: none;
    box-shadow: none;
    user-select: none;

    display: flex;
    flex-direction: row;

    .label {
        font-size: 9px;
        position: absolute;
        top: 8px;
        padding-left: 1px;
        color: $mid-grey;
        text-transform: uppercase;
        letter-spacing: 1px;
        z-index: $z-elevated + 2;
        pointer-events: none;
        font-weight: 600;
    }

    .group-by-select {
        border-right: $border;
        margin: 0 10px;
        flex: 1 1 auto;
        align-self: stretch;
        padding-top: 4px;

        .group-by-label {
            font-size: 9px;
            padding-left: 1px;
            color: $mid-grey;
            text-transform: uppercase;
            letter-spacing: 1px;
            pointer-events: none;
            font-weight: 600;
        }

        ul:before {
            content: ' ';
            display: block;
            height: 12px;
            width: 100%;
        }
    }

    .right {
        flex: 0 0 auto;
        align-self: stretch;
        justify-content: space-between;
        display: flex;
        flex-direction: column;

        .row {
            flex: 0 0 auto;
            align-self: stretch;
            vertical-align: middle;
            align-items: flex-end;
        }
    }

    .button-toggle-images {
        height: 30px;
        line-height: 30px;
        vertical-align: middle;
        width: auto;
    }

    .toggle-text {
        height: 16px;
        font-size: 8px;
        display: flex;
        cursor: pointer;

        z-index: 4;

        .toggle-left-text,
        .toggle-right-text {
            padding: 0 6px;
            display: flex;
            align-items: center;
            border-radius: 4px;
        }

        .toggle-label {
            color: darken($border-color, 25%);
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-weight: 600;

            &.selected {
                color: $blue;
                background: rgba($blue, 0.1);
                cursor: default;
            }

            &:not(&.selected):hover {
                color: darken($blue, 10%);
            }
        }
    }

    .model-select {
        position: relative;
        vertical-align: middle;
        font-size: 12px;
        color: $mid-grey;
        display: inline-block;
        float: left;
        margin-right: 9px;

        &.model-select-sort-by {
            .model-select-sort-by-header {
                display: flex;
                height: 24px;
                align-items: center;

                .model-select-sort-by-header-label {
                    font-size: 9px;
                    color: #777;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    z-index: 4;
                    pointer-events: none;
                    font-weight: 600;
                    margin-right: 6px;
                }
            }

            span.selected {
                margin-top: 0px;
                width: 150px;
            }

            select {
                top: 24px;
            }
        }

        &.model-select-display-by span.selected {
            width: 140px;
        }

        &.model-select-limit span.selected {
            width: 60px;
        }

        &:before {
            content: ' ';
            background: $white;
            z-index: $z-elevated;
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
            pointer-events: none;
        }

        span.selected {
            position: relative;
            display: table;
            margin-top: 24px;
            pointer-events: none;
            padding-left: 6px;
            padding-right: 3px;
            border: $border;
            z-index: $z-elevated + 1;
            height: 24 * 2px;

            .property {
                display: table-cell;
                vertical-align: middle;
                position: relative;
                .property-label {
                    font-size: 11px;
                    line-height: 18px;
                    display: inline-block;
                    width: calc(100% - 20px);
                }
                i {
                    position: absolute;
                    right: 0;
                    font-size: 14px;
                    top: 50%;
                    margin-top: -6px;
                    color: $light-grey;
                }
            }

            .group {
                font-weight: 300;
            }
        }

        select {
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            width: 100%; height: 100%;
            border: none;
            border-radius: 0px;
            font-size: 14px;
            background: none;
            color: $mid-grey;
        }

        &:not(&.model-select-sort-by):hover,
        .select-section:hover {
            .selected {
                color: $mid-grey;
                background: darken($white, 2%);
            }
        }
    }
}
