#app.route-metrics.disable-segments-metrics-page {
    #view {
        right: 0;
    }
    #sidebar {
        .smart-groups-panel {
            display: none;
        }
        .toggle-sidebar {
            display: none;
        }
        .calendar-popup:after {
            right: 100px;
        }
    }
}

.view-metrics {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $body-background-color;

    & > .loadable {
        background-color: $body-background-color;
        opacity: 0;
        &.loading {
            opacity: 1;
        }
        &:after {
            z-index: $z-modal;
        }
        &:before {
            z-index: $z-modal;
        }
    }

    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    margin-right: 4px;
    margin-left: 4px;

    $header-height: 160px;
    $metrics-funnel-node-actions-height: 30px;

    tabs-with-menu {
        z-index: $z-elevated;
    }


    // This is the top section:
    // - selected filters
    // - group by
    .metrics-funnel-breadcrumb {
        flex: 0 1 auto;
        align-self: stretch;
        position: relative;
        background: $white;

        .metrics-funnel-breadcrumb-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
        }

        .funnel-state, .available-properties {

            .properties-container {
                padding-right: 5px;
            }

            span.no-border {
                button {
                    border:none;
                }
            }

            & > header, .properties-container > header {

                h1, button {
                    display: inline-block;
                    line-height: 15px;
                    height: 15px;
                    font-size: 10px;
                    color: $mid-grey;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 1px;
                    border: none;
                }
                h1 {
                    margin-top: 10px;
                    margin-bottom: 11px;
                }
                button {
                    display: inline-block;
                    font-size: 10px;
                    margin-left: 8px;
                    color: rgba($blue, 0.5);
                    text-decoration: none;
                    &:hover {
                        color: $blue;
                        text-decoration: underline;
                    }
                }
            }
        }

        .available-properties {
            display: flex;
            flex-direction: row;
            position: relative;
            background: $white;
            min-height: 70px;

            .properties-container > header {
                padding-left: 10px;
            }

            .properties-container {
                flex: 1;
            }
        }

        .buttons-holder {
            flex: 0 0 125px;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-left: $border;

            .ui-metric-selector-tree-container {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;

                button.metric-expand {
                    width: 90px;
                    height: 50px;
                    top: 0px;
                    margin-right: 0px;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 8px;

                    i {
                        color: $mid-grey;
                        margin-left: unset;
                    }

                    span {
                        font-weight: 600;
                        font-size: 10px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                        line-height: 1.3;
                        width: unset;
                    }
                }

                .ui-metric-selector {
                    right: 0px;
                    top: 60px;
                }
            }

            .export-panel-button {
                border: none;
                height: 36px;
                width: 100px;
                flex: 0 0 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                font-size: 9px;
                color: $mid-grey;
                text-decoration: none;

                span {
                    font-weight: 600;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    width: 40px;
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    line-height: 1.3;
                }

                &:hover {
                    color: $blue;
                    cursor: pointer;
                }
            }
        }
    }


    // This is the bottom section:
    // - grid views
    // - grid
    .metrics-funnel-node {
        height: 100%;
        width: 100%;
        position: relative;
        flex: 1 1 auto;
        align-self: stretch;

        .grid-container {
            $grid-header-height: 70px;
            $grid-footer-height: 40px;

            position: absolute;
            $padding: 0px;
            top: 0px;
            left: $padding;
            bottom: $padding;
            right: $padding;
            border-top: 1px solid $border-color;

            .grid {
                .sortable:after {
                    display: none
                }
            }

            .grid-new {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                overflow: hidden;

                .ag-cell {
                    user-select: text;
                }

                .cell-column-pinned-total-value {
                    user-select: none;
                    font-size: 12px;
                    color: lighten($body-text-color, 10%) !important;
                }

                .ag-row .item-image-render span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .ag-pinned-left-header {
                    .ag-header-cell-text {
                        font-weight: 600;
                    }
                }

                .ag-pinned-left-cols-container {

                    .ag-cell[col-id="item_image__left"] {
                        padding: 0;
                    }

                    .ag-cell[col-id^="property"] {
                        span {
                            font-weight: 600;
                            text-decoration: underline;
                            text-decoration-color: lighten($body-text-color, 20%);
                            text-decoration-thickness: 0.5px;
                            text-underline-offset: 0.1rem;
                            word-break: break-word;
                        }
                        &:hover {
                            cursor: pointer;
                            span {
                                color: $blue;
                                text-decoration-color: lighten($blue, 10%);
                            }
                        }
                    }

                }
            }
        }
    }
}
