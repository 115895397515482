.ui-metric-selector-tree-container {
    display: inline-block;
    height: 100%;

    button.metric-expand {
        width: 90px;
        height: 48px;
        padding: 3px;
        line-height: 1;
        position: relative;
        top: 24px;
        margin-right: 10px;
        color: $mid-grey;
        float: left;
        display: inline-block;

        i {
            width: auto;
            opacity: 0.5;
            font-size: 12px;
            margin-left: -15px;
        }
        span {
            font-weight: 600;
            font-size: 9px;
            letter-spacing: 1px;
            text-transform: uppercase;
            width: 40px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            line-height: 1.3;
        }
        &:hover, &.active {
            border-color: rgba($blue, 0.5);
            i, span {
                color: $blue;
            }
        }
    }
}

.ui-metric-selector {
    position: absolute;
    height: 400px;
    width: 360px;
    right: 10px;
    top: 72px;
    bottom: 0px;
    z-index: $z-modal;
    border: $border;
    background: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 7px rgba(black, 0.08);

    $header-height: 45px;

    & > header {
        height: $header-height;
        border-bottom: $border;

        .metric-selector-filter {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                line-height: 100%;
                height: $header-height - 9px;
                display: block;
                border-left: $border;
                margin-right: 3px;
                font-size: 11px;
                min-width: 70px;
                color: $mid-grey;

                &:not(.active) {
                    display: none;
                }
            }

            input {
                flex-grow: 1;
                height: $header-height - 8px;
                border: none;
                display: block;
                padding-right: 6px;
                font-size: 12px;
            }

            i {
                display: block;
                width: 30px;
                font-size: 13px;
                color: $mid-grey;
                opacity: 0.6;
                height: $header-height - 1px;
                line-height: $header-height - 1px;
                text-align: center;
            }
        }
    }

    .metric-selector-tree {
        flex: 1 1;
        overflow-y: auto;
        .jstree-anchor {
            color: $dark-grey;
            font-size: 13px;
            .jstree-checkbox {
                margin-right: 4px;
            }
        }
        .jstree-search {
            color: $darker-grey;
        }
    }
}
